/*
// .arrow
*/
@import '../variables';
@import '../constants';
@import '../mixins/direction';
@import '../mixins/arrow';


@mixin local-define-arrow-scheme($state) {
    $bg-color-key:   #{$state}-bg-color;
    $font-color-key: #{$state}-font-color;

    @if(map_has_key($arrow-scheme, $font-color-key)) {
        color: map_get($arrow-scheme, $font-color-key);
    }
    @if(map_has_key($arrow-scheme, $bg-color-key)) {
        &:before {
            background: map_get($arrow-scheme, $bg-color-key);
        }
    }
}


$local-height: 23px;
$local-padding-x: 6px;
$local-angel: -20deg;


.arrow {}
.arrow__button {
    display: flex;
    position: relative;
    z-index: 0;
    align-items: center;
    border: none;
    background: transparent;
    pointer-events: none;
    transition: color .15s;

    @include local-define-arrow-scheme(normal);

    svg {
        fill: currentColor;
        display: block;

        @include direction {
            transform: scaleX($transform-direction);
        }
    }

    &:focus {
        outline: none;
    }
    &:before {
        position: absolute;
        display: block;
        content: '';
        height: 100%;
        transform-origin: center center;
        z-index: -1;
        border-radius: 2px;
        pointer-events: auto;
        transition: background .15s;
    }
    &:hover {
        @include local-define-arrow-scheme(hover);
    }
    &:active {
        @include local-define-arrow-scheme(active);

        &,
        &:before {
            transition-duration: 0s;
        }
    }

    // this is to avoid chrome rendering bug
    &:after {
        position: absolute;
        display: block;
        content: '';
        top: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        transition: background .2s;
        opacity: .01;

        @include direction {
            #{$inset-inline-start}: -2px;
        }
    }
    &:hover:after {
        background: $barely-black;
    }
    &:active:after {
        background: $barely-white;
        transition-duration: 0s;
    }
}
.arrow--next {
    svg {
        @include direction {
            #{$margin-inline-end}: -1px;
        }
    }
}
.arrow--prev {
    svg {
        @include direction {
            #{$margin-inline-start}: -1px;
        }
    }
}

@include arrow-size($local-height, $local-padding-x, $local-angel);
