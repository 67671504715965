/*
// .block-reviews
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';


.block-reviews {}
.block-reviews__title {
    font-size: 32px;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-bottom: 28px;
}
.block-reviews__subtitle {
    color: map_get($card-scheme, opposite-alt);
    text-align: center;
    margin-bottom: 28px;
    font-size: 15px;
    margin-top: -24px;
}
.block-reviews__list {
    .owl-stage-outer {
        margin: -10px;
        padding: 10px;
    }
    .owl-dots {
        margin-top: 18px;
        display: flex;
        justify-content: center;
    }
    .owl-carousel button.owl-dot {
        &:before {
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: $body-carousel-dot-normal-color;
            transition: background .2s;
            margin: 6px;
        }
        &:focus {
            outline: none;
        }
        &:hover:before {
            background: $body-carousel-dot-hover-color;
        }
        &.active:before {
            background: $body-carousel-dot-active-color;
        }
    }
}
.block-reviews__item {
    @include card;

    max-width: 690px;
    margin: 0 auto;
    display: flex;
}
.block-reviews__item-avatar {
    width: 190px;
    flex-shrink: 0;

    @include direction {
        #{$margin-inline-start}: 12px;
        #{$margin-inline-end}: -12px;
    }

    img {
        max-width: 100%;
    }
}
.block-reviews__item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 32px;
}
.block-reviews__item-text {
    font-size: 15px;
    font-style: italic;
    line-height: 24px;
}
.block-reviews__item-meta {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.block-reviews__item-author {
    color: map_get($card-scheme, opposite-alt);
    font-size: 14px;
    position: relative;
    line-height: 20px;

    &:before {
        display: inline-block;
        height: 1px;
        width: 18px;
        background: currentColor;
        content: '';
        opacity: .8;
        position: relative;
        vertical-align: middle;

        @include direction {
            #{$inset-inline-start}: 0;
            #{$margin-inline-end}: 4px;
        }
    }
}
.block-reviews__item-rating {
    padding-bottom: 4px;
}


@include media-breakpoint-down(sm) {
    .block-reviews__item {
        flex-direction: column;
    }
    .block-reviews__item-avatar {
        margin: 36px 36px 0;
        overflow: hidden;
        align-self: center;
        width: 140px;
        border-radius: (140px / 2);

        @include direction {
            #{$margin-inline-start}: 0;
            #{$margin-inline-end}: 0;
        }
    }
    .block-reviews__item-content {
        text-align: center;
    }
    .block-reviews__item-meta {
        flex-direction: column;
        margin-top: 0;
        padding: 28px 0 20px;
    }
    .block-reviews__item-rating {
        order: 1;
        padding-top: 8px;
        padding-bottom: 0;
    }
}
