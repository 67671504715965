/*
// .products-view
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/breakpoints';


.products-view {}
.products-view__options {
    @include card;

    margin-bottom: 20px;
}
.products-view__pagination {
    @include card;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: .75rem 1rem;
}
.products-view__pagination-legend {
    font-size: .9375rem;
    padding: 0 .375rem;
}


@include media-breakpoint-down(sm) {
    .products-view__pagination {
        padding-top: 1rem;
        flex-direction: column;
    }
    .products-view__pagination-legend {
        padding-top: 9px;
    }
}
